/* eslint-disable react/no-danger */
import React, { useContext, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw, { css, theme } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import * as Logo from "~components/svg/Logos.jsx";
import Button from "~components/Button.jsx";
import Go from "~components/Go.jsx";

const Footer = () => {
  const { sanityFooter } = useStaticQuery(graphql`
    query Footer {
      sanityFooter {
        subscribeSubheading
        subscribeSubheadingXS
        subscribePlaceholder
        subscribePlaceholderXS
        subscribeSuccess

        contactHeading
        contactEmail {
          buttonTarget
          buttonText
        }
        contactPhone {
          buttonTarget
          buttonText
        }

        socialHeading
        socialLinks {
          buttonTarget
          buttonText
        }

        addressHeading
        addressBody
        addressBodyXS

        navLinks {
          buttonTarget
          buttonText
        }

        footnote
        footnoteXS
        copyright
      }
    }
  `);

  // console.log(sanityFooter);

  // ===========================================================================
  // context / ref / state

  const { isDesktop, screen } = useContext(DocumentContext);

  // ===========================================================================
  // variables

  const submitRef = useRef();

  const [email, setEmail] = useState(null);
  const [formFocused, setFormFocused] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // ===========================================================================
  // methods

  const submitProxy = () => {
    if (submitRef?.current) {
      submitRef.current.click();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    if (submitting || submitted) {
      return false;
    }

    setSubmitting(true);

    const campaignMonitorData = {
      email
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/campaign-monitor`, {
      body: JSON.stringify(campaignMonitorData),
      method: `POST`
    })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(`CM response: `, response);
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });

    return false;
  };

  // ===========================================================================
  // render

  return (
    <footer
      css={[
        css`
          ${A.Keyframes(`appear`, `1s ${A.EASING_CUBIC} forwards`, `0.3s`)}
        `,
        tw`w-full relative block pt-6 sm:pt-16 pb-8 sm:pb-16 md:pb-24 bg-blue`
      ]}
    >
      <Grid>
        {!isDesktop() && (
          <T.Heading inject={{ tw: tw`col-span-12 mb-4` }} font="3" level="3">
            {sanityFooter.subscribeSubheadingXS}
          </T.Heading>
        )}

        <form
          css={[
            css`
              transition: opacity 0.3s ${A.EASING_CUBIC},
                transform 0.3s ${A.EASING_CUBIC};

              pointer-events: ${submitting || submitted ? `none` : `auto`};
            `,
            tw`col-span-12 relative md:mb-12 mb-4`
          ]}
          onSubmit={onSubmit}
        >
          <input
            ref={submitRef}
            css={[tw`w-0 h-0 opacity-0 absolute top-0 left-0`]}
            type="submit"
          />

          {isDesktop() && (
            <div
              css={[
                css`
                  //
                `,
                tw`mb-2`
              ]}
            >
              {submitted && (
                <T.Body
                  inject={{
                    css: `${A.Keyframes(
                      `appear`,
                      `0.3s ${A.EASING_CUBIC} forwards`,
                      `0`
                    )}`
                  }}
                  font="2"
                >
                  {sanityFooter.subscribeSuccess}
                </T.Body>
              )}

              {!submitted && (
                <T.Body
                  inject={{
                    css: `${A.Keyframes(
                      `appear`,
                      `0.3s ${A.EASING_CUBIC} forwards`,
                      `0`
                    )}`
                  }}
                  font="2"
                >
                  {sanityFooter.subscribeSubheading}
                </T.Body>
              )}
            </div>
          )}

          <input
            css={[
              css`
                opacity: ${submitted || submitting ? `0.25` : `1`};

                background: transparent;
                font-family: ${theme`fontFamily`.head.join()};
                letter-spacing: -0.03em;

                &::-webkit-input-placeholder {
                  color: ${theme`colors.grey`};
                }
                &::-moz-placeholder {
                  color: ${theme`colors.grey`};
                }
                &:-ms-input-placeholder {
                  color: ${theme`colors.grey`};
                }
                &:-moz-placeholder {
                  color: ${theme`colors.grey`};
                }
              `,
              tw`w-full h-12 relative block border-black border-b text-h3 md:text-h3-md mb-4`
            ]}
            onChange={(e) => setEmail(e.currentTarget.value)}
            onBlur={() => setFormFocused(false)}
            onFocus={() => setFormFocused(true)}
            placeholder={
              isDesktop()
                ? sanityFooter.subscribePlaceholder
                : sanityFooter.subscribePlaceholderXS
            }
            readOnly={submitting || submitted}
            required
            type="email"
          />

          <div
            css={[
              tw`h-12 md:h-full absolute top-0 right-0 bottom-0 z-10 flex items-center justify-center`
            ]}
          >
            <button type="button" onClick={submitProxy}>
              <Icon.Arrow
                inject={{
                  css: `
                  transition: transform 0.3s ${A.EASING_CUBIC};

                  @media not all and (pointer: coarse) {
                    &:hover {
                      transform: translate3d(0.25rem, 0, 0);
                    }
                  }
                `,
                  tw: tw`w-8 md:w-12`
                }}
                color="black"
              />
            </button>
          </div>
        </form>
      </Grid>

      <Grid inject={{ tw: tw`pt-6 pb-6` }}>
        <article css={[tw`col-span-6 md:col-span-3 pr-2 md:pr-0`]}>
          <T.Heading font="3" level="2" inject={{ tw: tw`mb-4` }}>
            {sanityFooter.contactHeading}
          </T.Heading>

          <T.Body
            font="2"
            inject={{
              css: `
                @media not all and (pointer: coarse) {
                  &:hover {
                    text-decoration: underline;
                  }
                }
              `
            }}
          >
            <a
              href={sanityFooter.contactEmail.buttonTarget}
              css={[
                css`
                  @media screen and (max-width: 1024px) {
                    text-decoration: underline;
                  }

                  @media not all and (pointer: coarse) {
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                `,
                tw`break-words`
              ]}
            >
              {screen && isDesktop()
                ? sanityFooter.contactEmail.buttonTarget.replace(`mailto:`, ``)
                : sanityFooter.contactEmail.buttonText}
            </a>
          </T.Body>

          <T.Body
            font="2"
            inject={{
              css: `
                @media not all and (pointer: coarse) {
                  &:hover {
                    text-decoration: underline;
                  }
                }
              `
            }}
          >
            <a href={sanityFooter.contactPhone.buttonTarget}>
              {sanityFooter.contactPhone.buttonText}
            </a>
          </T.Body>
        </article>

        {/* // */}

        <div css={[tw`col-span-6 md:col-span-3 sm:col-start-9 pr-2 md:pr-0`]}>
          <T.Heading font="3" level="2" inject={{ tw: tw`mb-4` }}>
            {sanityFooter.socialHeading}
          </T.Heading>

          {sanityFooter.socialLinks.map(({ buttonTarget, buttonText }) => (
            <Go to={buttonTarget} newTab>
              <Button
                color="black"
                inject={{ tw: tw`h-10 inline-block` }}
                text={buttonText}
                transparent
              />
            </Go>
          ))}
        </div>

        {isDesktop() && (
          <div css={[tw`col-span-3 col-start-10 relative`]}>
            <Logo.FooterLogo inject={{ tw: tw`w-full` }} />
          </div>
        )}
      </Grid>

      <Grid inject={{ tw: tw`pt-6 pb-6` }}>
        <article
          css={[
            tw`col-span-6 md:col-span-3 relative flex flex-col justify-end pr-2 md:pr-0`
          ]}
        >
          <T.Heading font="3" level="2" inject={{ tw: tw`pb-4` }}>
            {sanityFooter.addressHeading}
          </T.Heading>

          <T.Body font="2">
            <span
              css={[tw`block whitespace-pre-wrap`]}
              dangerouslySetInnerHTML={{
                __html: isDesktop()
                  ? sanityFooter.addressBody
                  : sanityFooter.addressBodyXS
              }}
            />
          </T.Body>
        </article>

        {/* // */}

        <ul
          css={[
            css`
              padding-bottom: ${isDesktop() ? `0` : `19px`};
            `,
            tw`col-span-6 md:col-span-3 sm:col-start-9 flex flex-col justify-end pr-2 md:pr-0`
          ]}
        >
          {sanityFooter?.navLinks.map(({ buttonTarget, buttonText }) => (
            <li key={buttonTarget}>
              <Go to={buttonTarget}>
                <T.Body
                  font="2"
                  inject={{
                    css: `
                    @media not all and (pointer: coarse) {
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  `,
                    tw: tw`inline-block`
                  }}
                >
                  {buttonText}
                </T.Body>
              </Go>
            </li>
          ))}
        </ul>

        {/* // */}

        {isDesktop() && (
          <div
            css={[
              css`
                a {
                  @media not all and (pointer: coarse) {
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              `,
              tw`col-span-3 col-start-10 relative flex flex-col justify-end`
            ]}
          >
            <T.Body font="2" inject={{ tw: tw`py-2` }}>
              <span
                css={[tw`block whitespace-pre-wrap`]}
                dangerouslySetInnerHTML={{ __html: sanityFooter.footnote }}
              />
            </T.Body>

            <T.Body font="2">{sanityFooter.copyright}</T.Body>
          </div>
        )}
      </Grid>

      {!isDesktop() && (
        <Grid inject={{ tw: tw`pt-6 pb-6` }}>
          <div
            css={[
              css`
                a {
                  @media not all and (pointer: coarse) {
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              `,
              tw`col-span-6 relative flex flex-col justify-end pr-2`
            ]}
          >
            <T.Body font="2">
              <span
                css={[tw`block whitespace-pre-wrap`]}
                dangerouslySetInnerHTML={{ __html: sanityFooter.footnoteXS }}
              />
            </T.Body>
          </div>

          <div css={[tw`col-span-6 relative flex flex-col justify-end pr-2`]}>
            <T.Body font="2">{sanityFooter.copyright}</T.Body>
          </div>

          <div css={[tw`col-span-10 relative mt-12 pr-2`]}>
            <Logo.FooterLogo inject={{ tw: tw`w-full` }} />
          </div>
        </Grid>
      )}
    </footer>
  );
};

export default Footer;
